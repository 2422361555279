import React from "react";

import PropTypes from "prop-types";

import "./footer4.css";

const Footer4 = () => {
  return (
    <footer className="footer4-footer7 thq-section-padding">
      <div className="footer4-max-width thq-section-max-width">
        <div className="footer4-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer4-row">
            <div className="footer4-container">
              <span className="thq-body-small">
                Copyright @ 2024 Green Serve LLC FZ
              </span>
            </div>
            <div className="footer4-footer-links">
              <a href="/how_it_works.html">
                <span>
                  <fragment>
                    <span className="footer4-text5 thq-body-small">
                      How it works
                    </span>
                  </fragment>
                </span>
              </a>
              <a href="/privacy.html">
                <span>
                  <fragment>
                    <span className="footer4-text4 thq-body-small">
                      Privacy Policy
                    </span>
                  </fragment>
                </span>
              </a>
              <a href="/terms.html">
                <span>
                  <fragment>
                    <span className="footer4-text5 thq-body-small">
                      Terms of Use
                    </span>
                  </fragment>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer4;
