import React from "react";

import PropTypes from "prop-types";

import "./cta26.css";

const CTA26 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="thq-section-max-width">
        <div className="cta26-accent2-bg">
          <div className="cta26-accent1-bg">
            <div className="cta26-container1">
              <div className="cta26-content">
                <span>
                  <fragment>
                    <span className="cta26-text2 thq-heading-2">
                      Welcome to S4Parts
                    </span>
                    <br></br>
                  </fragment>
                </span>
                <p>
                  <fragment>
                    <span className="cta26-text3 thq-body-large">
                      The ultimate mobile application designed to streamline the
                      <br />
                      process of procuring auto spare parts for mechanics. With
                      <br />
                      S4Parts, finding the right spare part has never been
                      <br />
                      easier or more efficient.
                    </span>
                  </fragment>
                </p>
              </div>
              <div className="cta26-actions">
                <video width="320" autoplay muted loop playsinline controls>
                  <source src="/how_it_works.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CTA26.defaultProps = {
  heading1: undefined,
  imageAlt: "image",
  imageSrc: "https://play.teleporthq.io/static/svg/default-img.svg",
  content1: undefined,
};

CTA26.propTypes = {
  heading1: PropTypes.element,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  content1: PropTypes.element,
};

export default CTA26;
