import React from "react";

import PropTypes from "prop-types";

import "./steps2.css";

const Steps2 = (props) => {
  return (
    <div className="steps2-container thq-section-padding">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container1 thq-grid-2">
          <div className="steps2-section-header">
            <h2 className="thq-heading-2">Discover the Power of S4Parts</h2>
            <p className="thq-body-large">
              Experience the future of auto spare part procurement with S4Parts.
              Simplify your workflow, save time, and get the parts you need,
              when you need them. Download S4Parts today and focus on fixing the
              cars instead of finding auto parts. Take your Mechanic services to
              the next level!
            </p>
            <div className="steps2-actions">
              <button className="thq-button-filled thq-button-animated steps2-button">
                <span className="thq-body-small">Download our App</span>
              </button>
            </div>
          </div>
          <div className="steps2-container2">
            <div className="steps2-container3 thq-card">
              <h2>
                {props.step1Title ?? (
                  <fragment>
                    <span className="steps2-text16 thq-heading-2">
                      Raise Inquiry
                    </span>
                  </fragment>
                )}
              </h2>
              <span>
                {props.step1Description ?? (
                  <fragment>
                    <span className="steps2-text17 thq-body-small">
                      Mechanic raises an inquiry for an auto spare part by
                      sending a message through the application.
                    </span>
                  </fragment>
                )}
              </span>
              <label className="steps2-text05 thq-heading-3">01</label>
            </div>
            <div className="steps2-container4 thq-card">
              <h2>
                {props.step2Title ?? (
                  <fragment>
                    <span className="steps2-text18 thq-heading-2">
                      Receive Bids
                    </span>
                  </fragment>
                )}
              </h2>
              <span>
                {props.step2Description ?? (
                  <fragment>
                    <span className="steps2-text20 thq-body-small">
                      All registered suppliers receive the inquiry and bid for
                      prices if the auto part is available.
                    </span>
                  </fragment>
                )}
              </span>
              <label className="steps2-text08 thq-heading-3">02</label>
            </div>
            <div className="steps2-container5 thq-card">
              <h2>
                {props.step3Title ?? (
                  <fragment>
                    <span className="steps2-text19 thq-heading-2">
                      Select Auto Part
                    </span>
                  </fragment>
                )}
              </h2>
              <span>
                {props.step3Description ?? (
                  <fragment>
                    <span className="steps2-text21 thq-body-small">
                      Mechanic selects the auto part from the bids received
                      based on price and availability.
                    </span>
                  </fragment>
                )}
              </span>
              <label className="steps2-text11 thq-heading-3">03</label>
            </div>
            <div className="steps2-container6 thq-card">
              <h2>
                {props.step4Title ?? (
                  <fragment>
                    <span className="steps2-text22 thq-heading-2">
                      Order and Delivery
                    </span>
                  </fragment>
                )}
              </h2>
              <span>
                {props.step4Description ?? (
                  <fragment>
                    <span className="steps2-text15 thq-body-small">
                      Once the auto part is selected, the order is placed, and
                      we take care of delivering the auto part from the supplier
                      to the mechanic.
                    </span>
                  </fragment>
                )}
              </span>
              <label className="steps2-text14 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Steps2.defaultProps = {
  step4Description: undefined,
  step1Title: undefined,
  step1Description: undefined,
  step2Title: undefined,
  step3Title: undefined,
  step2Description: undefined,
  step3Description: undefined,
  step4Title: undefined,
};

Steps2.propTypes = {
  step4Description: PropTypes.element,
  step1Title: PropTypes.element,
  step1Description: PropTypes.element,
  step2Title: PropTypes.element,
  step3Title: PropTypes.element,
  step2Description: PropTypes.element,
  step3Description: PropTypes.element,
  step4Title: PropTypes.element,
};

export default Steps2;
