import React from "react";

import { Helmet } from "react-helmet";

import Navbar8 from "../components/navbar8";
import ContactForm7 from "../components/contact-form7";
import Footer4 from "../components/footer4";
import "./contact-us.css";

const ContactUs = (props) => {
  return (
    <div className="contact-us-container">
      <Helmet>
        <title>Contact-Us - Enchanting General Gaur</title>
        <meta
          property="og:title"
          content="Contact-Us - Enchanting General Gaur"
        />
      </Helmet>
      <Navbar8
        link1={
          <fragment>
            <span className="contact-us-text thq-body-small thq-link">
              Home
            </span>
          </fragment>
        }
        link2={
          <fragment>
            <span className="contact-us-text01 thq-body-small thq-link">
              How It Works
            </span>
          </fragment>
        }
        link3={
          <fragment>
            <span className="contact-us-text02 thq-body-small thq-link">
              About Us
            </span>
          </fragment>
        }
        link4={
          <fragment>
            <span className="contact-us-text03 thq-body-small thq-link">
              Contact Us
            </span>
          </fragment>
        }
        page1={
          <fragment>
            <span className="contact-us-text04 thq-body-large">Home</span>
          </fragment>
        }
        page2={
          <fragment>
            <span className="contact-us-text05 thq-body-large">
              How It Works
            </span>
          </fragment>
        }
        page3={
          <fragment>
            <span className="contact-us-text06 thq-body-large">About Us</span>
          </fragment>
        }
        page4={
          <fragment>
            <span className="contact-us-text07 thq-body-large">Contact Us</span>
          </fragment>
        }
        action1={
          <fragment>
            <span className="contact-us-text08">Download Now</span>
          </fragment>
        }
        action2={
          <fragment>
            <span className="contact-us-text09">Learn More</span>
          </fragment>
        }
        page1Description={
          <fragment>
            <span className="contact-us-text10 thq-body-small">
              Learn more about our mobile application and its features.
            </span>
          </fragment>
        }
        page2Description={
          <fragment>
            <span className="contact-us-text11 thq-body-small">
              Discover how our application simplifies the process of ordering
              auto spare parts.
            </span>
          </fragment>
        }
        page3Description={
          <fragment>
            <span className="contact-us-text12 thq-body-small">
              Find out more about our company and mission.
            </span>
          </fragment>
        }
        page4Description={
          <fragment>
            <span className="contact-us-text13 thq-body-small">
              Get in touch with us for any inquiries or support.
            </span>
          </fragment>
        }
      ></Navbar8>
      <ContactForm7
        email={
          <fragment>
            <span className="contact-us-text14 thq-body-small">
              info@s4parts.com
            </span>
          </fragment>
        }
        phone={
          <fragment>
            <span className="contact-us-text15 thq-body-small">
              +971 56 507 3949
            </span>
          </fragment>
        }
        action={
          <fragment>
            <span className="contact-us-text16 thq-body-small">
              Contact us now
            </span>
          </fragment>
        }
        address={
          <fragment>
            <span className="contact-us-text17 thq-body-small">
              Meydan Grandstand, 6th floor, Meydan Road, Nad Al Sheba, Dubai,
              UAE
            </span>
          </fragment>
        }
        content1={
          <fragment>
            <span className="contact-us-text18 thq-body-small">
              <span>Have a question or need support?</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </fragment>
        }
        content2={
          <fragment>
            <span className="contact-us-text21 thq-body-small">
              Our team is here to help you with any inquiries or assistance you
              may need.
            </span>
          </fragment>
        }
        heading1={
          <fragment>
            <span className="contact-us-text22 thq-heading-2">
              Get in touch
            </span>
          </fragment>
        }
        rootClassName="contact-form7-root-class-name"
      ></ContactForm7>
      <Footer4
        termsLink={
          <fragment>
            <span className="contact-us-text23 thq-body-small">
              Terms of Use
            </span>
          </fragment>
        }
        cookiesLink={
          <fragment>
            <span className="contact-us-text24 thq-body-small">
              Cookies Policy
            </span>
          </fragment>
        }
        privacyLink={
          <fragment>
            <span className="contact-us-text25 thq-body-small">
              Privacy Policy
            </span>
          </fragment>
        }
      ></Footer4>
    </div>
  );
};

export default ContactUs;
