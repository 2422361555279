import React from "react";

import { Helmet } from "react-helmet";

import Navbar8 from "../components/navbar8";
import CTA26 from "../components/cta26";
import Steps2 from "../components/steps2";
import ContactForm5 from "../components/contact-form5";
import Footer4 from "../components/footer4";
import "./home.css";

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>S4Parts</title>
        <meta name="description" content="Welcome to the S4 Parts. The ultimate mobile application designed to streamline the process of procuring auto spare parts for mechanics."/>
        <meta property="og:title" content="S4Parts" />
      </Helmet>
      <Navbar8
        link1={
          <fragment>
            <span className="home-text thq-body-small thq-link">Home</span>
          </fragment>
        }
        link4={
          <fragment>
            <span className="home-text03 thq-body-small thq-link">
              Contact Us
            </span>
          </fragment>
        }
      ></Navbar8>
      <CTA26 imageSrc="/s4parts_screen.png"></CTA26>
      <Steps2
        step1Title={
          <fragment>
            <span className="home-text16 thq-heading-2">Raise Inquiry</span>
          </fragment>
        }
        step2Title={
          <fragment>
            <span className="home-text17 thq-heading-2">Receive Bids</span>
          </fragment>
        }
        step3Title={
          <fragment>
            <span className="home-text18 thq-heading-2">Select Auto Part</span>
          </fragment>
        }
        step4Title={
          <fragment>
            <span className="home-text19 thq-heading-2">
              Order and Delivery
            </span>
          </fragment>
        }
        step1Description={
          <fragment>
            <span className="home-text20 thq-body-small">
              Mechanic raises an inquiry for an auto spare part by sending a
              message through the application.
            </span>
          </fragment>
        }
        step2Description={
          <fragment>
            <span className="home-text21 thq-body-small">
              All registered suppliers receive the inquiry and bid for prices if
              the auto part is available.
            </span>
          </fragment>
        }
        step3Description={
          <fragment>
            <span className="home-text22 thq-body-small">
              Mechanic selects the auto part from the bids received based on
              price and availability.
            </span>
          </fragment>
        }
        step4Description={
          <fragment>
            <span className="home-text23 thq-body-small">
              Once the auto part is selected, the order is placed, and we take
              care of delivering the auto part from the supplier to the
              mechanic.
            </span>
          </fragment>
        }
      ></Steps2>
      <Footer4
        termsLink={
          <a href="/tnc">
            <fragment>
              <span className="home-text30 thq-body-small">Terms of Use</span>
            </fragment>
          </a>
        }
        cookiesLink={
          <fragment>
            <span className="home-text31 thq-body-small">Cookies Policy</span>
          </fragment>
        }
        privacyLink={
          <fragment>
            <span className="home-text32 thq-body-small">Privacy Policy</span>
          </fragment>
        }
      ></Footer4>
    </div>
  );
};

export default Home;
