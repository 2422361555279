import React, { useState } from "react";

import PropTypes from "prop-types";

import "./navbar8.css";

const Navbar8 = (props) => {
  const [link5AccordionOpen, setLink5AccordionOpen] = useState(false);
  const [link5DropdownVisible, setLink5DropdownVisible] = useState(false);
  return (
    <header className="navbar8-container">
      <header data-thq="thq-navbar" className="navbar8-navbar-interactive">
        <img
          alt={props.logoAlt}
          src={props.logoSrc}
          className="navbar8-image1"
        />
        <div data-thq="thq-navbar-nav" className="navbar8-desktop-menu">
          <nav className="navbar8-links">
            <a href={props.link1Url}>
              {/* {props.link1 ?? ( */}
              <fragment>
                <span className="navbar8-text10 thq-body-small thq-link">
                  Home
                </span>
              </fragment>
              {/* )} */}
            </a>
            <div
              onClick={() => setLink5DropdownVisible(!link5DropdownVisible)}
              className="navbar8-link4-dropdown-trigger"
            >
              <span>
                <a href={props.link4Url}>
                  <fragment>
                    <span className="navbar8-text12 thq-body-small thq-link">
                      Contact Us
                    </span>
                  </fragment>
                </a>
              </span>
            </div>

            <div
              onClick={() => setLink5DropdownVisible(!link5DropdownVisible)}
              className="navbar8-link4-dropdown-trigger"
            >
              <span>
                <a href="/how_it_works.html">
                  <fragment>
                    <span className="navbar8-text12 thq-body-small thq-link">
                      How it Works
                    </span>
                  </fragment>
                </a>
              </span>
            </div>
          </nav>
          <div className="navbar8-buttons">
            {/* <button className="navbar8-action1 thq-button-filled thq-button-animated">
              <span className="thq-body-small">
                {props.action1 ?? (
                  <fragment>
                    <span className="navbar8-text08">Order Now</span>
                  </fragment>
                )}
              </span>
            </button> */}
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbar8-burger-menu">
          <svg viewBox="0 0 1024 1024" className="navbar8-icon02">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
      </header>
    </header>
  );
};

Navbar8.defaultProps = {
  page4Description: undefined,
  page4ImageAlt: "Contact Us Page Image",
  page3Description: undefined,
  page2: undefined,
  page1ImageSrc:
    "https://images.unsplash.com/photo-1604068105030-06d82bb48fd1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMjA4NTgzOHw&ixlib=rb-4.0.3&q=80&w=1080",
  linkUrlPage2: "How It Works",
  page4ImageSrc: "https://play.teleporthq.io/static/svg/default-img.svg",
  page2ImageAlt: "How It Works Page Image",
  logoAlt: "Mechanic App Logo",
  page1ImageAlt: "Home Page Image",
  page1Description: undefined,
  page2ImageSrc:
    "https://images.unsplash.com/photo-1696609881842-b252f5aa155b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMjA4NTgzOXw&ixlib=rb-4.0.3&q=80&w=1080",
  page4: undefined,
  action1: undefined,
  page3: undefined,
  link3Url: "/about-us",
  link1: undefined,
  page3ImageSrc:
    "https://images.unsplash.com/photo-1486927010601-24c8bd1bfe54?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMjA4NTgzOXw&ixlib=rb-4.0.3&q=80&w=1080",
  page1: undefined,
  link4: undefined,
  linkUrlPage3: "About Us",
  page3ImageAlt: "About Us Page Image",
  link3: undefined,
  link2: undefined,
  linkUrlPage1: "Home",
  link1Url: "/",
  link4Url: "/contact-us",
  page2Description: undefined,
  linkUrlPage4: "/contact-us",
  link2Url: "/how-it-works",
  action2: undefined,
  logoSrc: "/logo_n2.png",
};

Navbar8.propTypes = {
  page4Description: PropTypes.element,
  page4ImageAlt: PropTypes.string,
  page3Description: PropTypes.element,
  page2: PropTypes.element,
  page1ImageSrc: PropTypes.string,
  linkUrlPage2: PropTypes.string,
  page4ImageSrc: PropTypes.string,
  page2ImageAlt: PropTypes.string,
  logoAlt: PropTypes.string,
  page1ImageAlt: PropTypes.string,
  page1Description: PropTypes.element,
  page2ImageSrc: PropTypes.string,
  page4: PropTypes.element,
  action1: PropTypes.element,
  page3: PropTypes.element,
  link3Url: PropTypes.string,
  link1: PropTypes.element,
  page3ImageSrc: PropTypes.string,
  page1: PropTypes.element,
  link4: PropTypes.element,
  linkUrlPage3: PropTypes.string,
  page3ImageAlt: PropTypes.string,
  link3: PropTypes.element,
  link2: PropTypes.element,
  linkUrlPage1: PropTypes.string,
  link1Url: PropTypes.string,
  page2Description: PropTypes.element,
  linkUrlPage4: PropTypes.string,
  link2Url: PropTypes.string,
  action2: PropTypes.element,
  logoSrc: PropTypes.string,
};

export default Navbar8;
