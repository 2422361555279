import React from "react";

import PropTypes from "prop-types";

import "./contact-form5.css";

const ContactForm5 = (props) => {
  return (
    <div className="contact-form5-contact1 thq-section-padding">
      <div className="thq-flex-column thq-section-max-width">
        <div className="contact-form5-section-title thq-flex-column">
          <span>
            {props.content2 ?? (
              <fragment>
                <span className="contact-form5-text14 thq-body-small">
                  Get in touch with us today!
                </span>
              </fragment>
            )}
          </span>
          <div className="contact-form5-content">
            <h2>
              {props.heading1 ?? (
                <fragment>
                  <span className="contact-form5-text09 thq-heading-2">
                    Contact us
                  </span>
                </fragment>
              )}
            </h2>
            <span>
              {props.content1 ?? (
                <fragment>
                  <span className="contact-form5-text11 thq-body-small">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                </fragment>
              )}
            </span>
          </div>
        </div>
        <form className="contact-form5-form thq-flex-column">
          <div className="contact-form5-container">
            <div className="contact-form5-input">
              <label
                htmlFor="contact-form-5-first-name"
                className="thq-body-small"
              >
                First name
              </label>
              <input
                type="text"
                id="contact-form-5-first-name"
                placeholder="First Name"
                className="thq-input"
              />
            </div>
            <div className="contact-form5-input1">
              <label
                htmlFor="contact-form-5-last-name"
                className="thq-body-small"
              >
                Last name
              </label>
              <input
                type="text"
                id="contact-form-5-last-name"
                placeholder="Last Name"
                className="thq-input"
              />
            </div>
          </div>
          <div className="contact-form5-container1">
            <div className="contact-form5-input2">
              <label htmlFor="contact-form-5-email" className="thq-body-small">
                Email
              </label>
              <input
                type="email"
                id="contact-form-5-email"
                required="true"
                placeholder="Email"
                className="thq-input"
              />
            </div>
            <div className="contact-form5-input3">
              <label htmlFor="contact-form-5-phone" className="thq-body-small">
                Phone Number
              </label>
              <input
                type="tel"
                id="contact-form-5-phone"
                placeholder="Phone Number"
                className="thq-input"
              />
            </div>
          </div>
          <div className="contact-form5-container2">
            <div className="contact-form5-input4"></div>
          </div>
          <div className="contact-form5-container3">
            <label htmlFor="contact-form-5-message" className="thq-body-small">
              Message
            </label>
            <textarea
              id="contact-form-5-message"
              rows="3"
              placeholder="Enter your message"
              className="thq-input contact-form5-textarea"
            ></textarea>
          </div>
          <button
            type="submit"
            className="contact-form5-button thq-button-filled"
          >
            <span>
              {props.action ?? (
                <fragment>
                  <span className="contact-form5-text10 thq-body-small">
                    Submit
                  </span>
                </fragment>
              )}
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};

ContactForm5.defaultProps = {
  heading1: undefined,
  action: undefined,
  content1: undefined,
  content2: undefined,
};

ContactForm5.propTypes = {
  heading1: PropTypes.element,
  action: PropTypes.element,
  content1: PropTypes.element,
  content2: PropTypes.element,
};

export default ContactForm5;
